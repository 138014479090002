

























































































@import '@design';

.is-dirty {
  min-height: 10px;
}
.attr-list-enter-active,
.attr-list-leave-active {
  transition: opacity 0.2s;
}
.attr-list-enter,
.attr-list-leave-active {
  opacity: 0;
}
.attr-list-enter-active {
  transition-delay: 0;
}

.attr-list-move {
  transition: transform 0.2s;
}
